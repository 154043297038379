import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import quizStore from '../store/QuizStore';
import { quickToast } from '../utils/toast';
import { Button } from './ui/button';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from './ui/dialog';
import { Input } from './ui/input';
import { Label } from './ui/label';

interface QuestionFormDialogProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const QuestionFormDialog: React.FC<QuestionFormDialogProps> = ({
  open,
  setOpen,
}) => {
  const [question, setQuestion] = useState<string>('');
  const [answer, setAnswer] = useState<string>('');

  const addQuestion = (): { id: number } => {
    const questionObj = quizStore.addQuestion(question, answer);
    setQuestion('');
    setAnswer('');
    return questionObj;
  };

  const handleAddApprovedQuestion = (): void => {
    const questionObj = addQuestion();
    quizStore.approveQuestion(questionObj.id);
    quickToast("Added to approved questions, you're good to go!");
    setOpen(false);
  };

  const handleAddToQuiz = (): void => {
    const questionObj = addQuestion();
    quizStore.addQuestionToQuiz(questionObj.id);
    quickToast("Added to quiz questions, you're good to go!");
    setOpen(false);
  };

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>Add New Question</DialogTitle>
          <DialogDescription>
            Enter the question and answer below. Click save when you're done.
          </DialogDescription>
        </DialogHeader>
        <div className="grid gap-4 py-4">
          <div className="grid grid-cols-4 items-center gap-4">
            <Label htmlFor="question" className="text-right">
              Question
            </Label>
            <Input
              id="question"
              value={question}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setQuestion(e.target.value)
              }
              className="col-span-3"
            />
          </div>
          <div className="grid grid-cols-4 items-center gap-4">
            <Label htmlFor="answer" className="text-right">
              Answer
            </Label>
            <Input
              id="answer"
              value={answer}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setAnswer(e.target.value)
              }
              className="col-span-3"
            />
          </div>
        </div>
        <DialogFooter>
          <Button variant={'ghost'} onClick={handleAddApprovedQuestion}>
            Add to Approved
          </Button>
          <Button onClick={handleAddToQuiz}>Add to Quiz</Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default observer(QuestionFormDialog);

export const openQuestionFormDialog = (
  setOpen: React.Dispatch<React.SetStateAction<boolean>>,
): void => {
  setOpen(true);
};
