import SettingsModal from './SettingsModal';
import { Tabs, TabsList, TabsTrigger } from './ui/tabs';

export const Navbar = ({
  activeComponent,
  setActiveComponent,
}: {
  activeComponent: string;
  setActiveComponent: (component: string) => void;
}) => {
  return (
    <div className="flex justify-between items-center p-4 bg-background border-b">
      <div className="flex items-center">
        <h3 className="text-2xl font-bold text-primary">Quizzard</h3>
      </div>
      <div className="flex items-center">
        <Tabs value={activeComponent} onValueChange={setActiveComponent}>
          <TabsList>
            <TabsTrigger value="Quizzes">Quizzes</TabsTrigger>
            <TabsTrigger value="GenerateQuestions">Work on Quiz</TabsTrigger>
            <TabsTrigger value="ScrollQuiz">Preview Quiz</TabsTrigger>
          </TabsList>
        </Tabs>
        <SettingsModal />
      </div>
    </div>
  );
};
