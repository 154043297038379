import { Archive, CheckSquare, List, RefreshCw, Tags } from 'lucide-react';
import { observer } from 'mobx-react-lite';
import React from 'react';
import QuestionTable from '../components/QuestionTable';
import { Card, CardContent } from '../components/ui/card';
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from '../components/ui/tabs';

import GenerateQuestionsPane from '../components/GenerateQuestionsPane';
import quizStore from '../store/QuizStore';
import CategoriesPage from './CategoriesPage';

const MakeQuizPage: React.FC = () => {
  const components = [
    {
      name: 'current-quiz',
      label: 'Quiz',
      icon: List,
      component: (
        <QuestionTable
          questions={quizStore.currentQuizQuestions}
          isSingleQuiz={true}
        />
      ),
    },
    {
      name: 'approved-questions',
      label: 'Approved',
      icon: CheckSquare,
      component: <QuestionTable questions={quizStore.approvedQuestions} />,
    },
    {
      name: 'generate-questions',
      label: 'Generate',
      icon: RefreshCw,
      component: <GenerateQuestionsPane />,
    },
    {
      name: 'past-quizzes',
      label: 'Past Quizzes',
      icon: Archive,
      component: (
        <QuestionTable
          questions={quizStore.pastQuizQuestions}
          columnState={{ quizName: true }}
        />
      ),
    },
    {
      name: 'categories',
      label: 'Categories',
      icon: Tags,
      component: <CategoriesPage />,
    },
  ];

  return (
    <div className="flex flex-row space-x-4 p-4">
      <Card className="w-1/2 min-w-fit">
        <CardContent className="p-6">
          <Tabs defaultValue="current-quiz" className="w-full">
            <TabsList className="grid w-full grid-cols-5">
              {components.map((item) => (
                <TabsTrigger
                  key={item.name}
                  value={item.name}
                  className="flex items-center"
                >
                  <item.icon className="mr-2 h-4 w-4" />
                  {item.label}
                </TabsTrigger>
              ))}
            </TabsList>
            {components.map((item) => (
              <TabsContent key={item.name} value={item.name}>
                <h2 className="text-2xl font-bold mb-4">{item.label}</h2>
                {item.component}
              </TabsContent>
            ))}
          </Tabs>
        </CardContent>
      </Card>

      <Card className="w-1/2 min-w-fit">
        <CardContent className="p-6">
          <Tabs defaultValue="scratchpad" className="w-full">
            <TabsList className="grid w-full grid-cols-2">
              <TabsTrigger value="scratchpad">Scratchpad</TabsTrigger>
              <TabsTrigger value="quiz">Quiz</TabsTrigger>
            </TabsList>
            <TabsContent value="scratchpad">
              <QuestionTable questions={quizStore.scratchpadQuestions} />
            </TabsContent>
            <TabsContent value="quiz">
              <QuestionTable
                questions={quizStore.currentQuizQuestions}
                isSingleQuiz={true}
              />
            </TabsContent>
          </Tabs>
        </CardContent>
      </Card>
    </div>
  );
};

export default observer(MakeQuizPage);
