import {
  ArrowUp,
  ChevronDown,
  ChevronUp,
  Copy,
  Eye,
  EyeOff,
  Share2,
  X,
} from 'lucide-react';
import React, { useEffect, useRef, useState } from 'react';
import quizStore from '../store/QuizStore';
import { Button } from './ui/button';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from './ui/dialog';

interface Question {
  id: number;
  text: string;
  answer: string;
}

interface Quiz {
  id: number;
  title: string;
  questions: Question[];
}

const ScrollQuiz: React.FC = () => {
  const currentQuizLength = quizStore.currentQuizQuestions.length;
  const [currentQuiz, setCurrentQuiz] = useState(0);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [showAnswer, setShowAnswer] = useState<boolean[]>(
    new Array(currentQuizLength).fill(false),
  );
  const [score, setScore] = useState<number>(0);
  const [answered, setAnswered] = useState<(boolean | null)[]>(
    new Array(currentQuizLength).fill(null),
  );
  const [copyButtonText, setCopyButtonText] = useState('Copy to Clipboard');
  const [showShareModal, setShowShareModal] = useState(false);
  const [shareText, setShareText] = useState('');
  const questionRefs = useRef<(HTMLDivElement | null)[]>([]);
  const shareRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const newScore = answered.filter((answer) => answer === true).length;
    setScore(newScore);
  }, [answered]);

  const navigateQuestion = (direction: number) => {
    const newIndex = currentQuestion + direction;
    if (newIndex >= 0 && newIndex < currentQuizLength) {
      scrollToQuestion(newIndex);
    } else if (newIndex === currentQuizLength) {
      // Scroll to share screen if we've passed the last question
      shareRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  };

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'ArrowUp') {
        event.preventDefault();
        navigateQuestion(-1);
      } else if (event.key === 'ArrowDown') {
        event.preventDefault();
        navigateQuestion(1);
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [currentQuestion]);

  const scrollToQuestion = (index: number) => {
    if (index >= currentQuizLength) {
      // Scroll to share screen if index is beyond questions
      shareRef.current?.scrollIntoView({ behavior: 'smooth' });
    } else {
      questionRefs.current[index]?.scrollIntoView({ behavior: 'smooth' });
      setCurrentQuestion(index);
    }
  };

  const scrollToTop = () => {
    scrollToQuestion(0);
  };

  const toggleAnswer = (index: number) => {
    setShowAnswer((prev) => {
      const newState = [...prev];
      newState[index] = !newState[index];
      return newState;
    });
  };

  const handleAnswer = (index: number, isCorrect: boolean) => {
    setAnswered((prev) => {
      const newState = [...prev];
      newState[index] = isCorrect;
      return newState;
    });
  };

  const changeQuiz = (quizIndex: number) => {
    setCurrentQuiz(quizIndex);
    setCurrentQuestion(0);
    setShowAnswer(new Array(currentQuizLength).fill(false));
    setAnswered(new Array(currentQuizLength).fill(null));
  };

  const generateShareText = () => {
    const quiz = quizStore.currentQuizQuestions;
    const totalQuestions = currentQuizLength;
    const scoreEmoji = answered.map((a) => (a === true ? '🟩' : '⬜'));

    let scoreRows = '';
    for (let i = 0; i < scoreEmoji.length; i += 5) {
      scoreRows += scoreEmoji.slice(i, i + 5).join('') + '\n';
    }

    scoreRows = scoreRows.trim();

    return `${quizStore.currentQuiz} 👀
Score: ${score}/${totalQuestions}
${scoreRows}
Can you beat my score? Try at gustoquiz.com!`;
  };

  const handleShare = () => {
    setShareText(generateShareText());
    setShowShareModal(true);
  };

  const handleCopy = () => {
    const shareText = generateShareText();
    navigator.clipboard.writeText(shareText).then(() => {
      setCopyButtonText('Copied!');
      setTimeout(() => setCopyButtonText('Copy to Clipboard'), 3000);
    });
  };

  return (
    <div className="min-h-screen bg-gray-100">
      <div className="fixed bottom-0 left-0 bg-transparent p-4 z-10 flex space-x-4">
        <select
          className="flex-1 p-2 border rounded bg-transparent"
          value={currentQuiz}
          onChange={(e) => changeQuiz(Number(e.target.value))}
        >
          {quizStore.quizzes.map((quiz, index) => (
            <option key={quiz.name} value={index}>
              {quiz.name}
            </option>
          ))}
        </select>
        <select
          className="flex-1 p-2 border rounded bg-transparent"
          value={currentQuestion}
          onChange={(e) => scrollToQuestion(Number(e.target.value))}
        >
          {quizStore.currentQuizQuestions.map((q, index: number) => (
            <option key={q.id} value={index}>
              Question {q.number}
            </option>
          ))}
        </select>
        <button
          onClick={scrollToTop}
          className="p-2 border rounded bg-transparent hover:bg-gray-200 transition-colors"
          title="Go to Top"
        >
          <ArrowUp size={24} />
        </button>
      </div>

      {quizStore.currentQuizQuestions.map((question, index: number) => (
        <div
          key={question.number}
          ref={(el) => (questionRefs.current[index] = el)}
          className="min-h-screen flex flex-col justify-center items-center p-8 relative"
          style={{
            backgroundColor: `hsl(${index * 72}, 70%, 80%)`,
          }}
        >
          <h2 className="text-3xl font-bold mb-4">
            Question {question.number}
          </h2>
          <p
            className="text-xl mb-8 max-w-lg text-center
          "
          >
            {question.question}
          </p>

          <button
            onClick={() => toggleAnswer(index)}
            className="px-4 py-2 bg-black text-white rounded-full hover:bg-gray-800 transition-colors flex items-center space-x-2"
          >
            {showAnswer[index] ? (
              <>
                <EyeOff size={20} />
                <span>Hide Answer</span>
              </>
            ) : (
              <>
                <Eye size={20} />
                <span>Reveal Answer</span>
              </>
            )}
          </button>

          {showAnswer[index] && (
            <div className="text-center mt-4">
              <p className="text-2xl font-semibold mb-4">{question.answer}</p>
              <div className="space-x-4">
                <button
                  onClick={() => handleAnswer(index, true)}
                  className={`px-4 py-2 rounded-full transition-colors ${
                    answered[index] === true
                      ? 'bg-green-500 text-white'
                      : 'bg-black text-white hover:bg-gray-800'
                  }`}
                >
                  Correct
                </button>
                <button
                  onClick={() => handleAnswer(index, false)}
                  className={`px-4 py-2 rounded-full transition-colors ${
                    answered[index] === false
                      ? 'bg-red-500 text-white'
                      : 'bg-black text-white hover:bg-gray-800'
                  }`}
                >
                  Incorrect
                </button>
              </div>
            </div>
          )}

          {index < currentQuizLength - 1 ? (
            <ChevronDown
              className="absolute bottom-8 cursor-pointer text-gray-600 hover:text-black transition-colors"
              size={48}
              onClick={() => scrollToQuestion(index + 1)}
            />
          ) : (
            <ChevronDown
              className="absolute bottom-8 cursor-pointer text-gray-600 hover:text-black transition-colors"
              size={48}
              onClick={() => scrollToQuestion(currentQuizLength)}
            />
          )}
          {index > 0 && (
            <ChevronUp
              className="absolute top-20 cursor-pointer text-gray-600 hover:text-black transition-colors"
              size={48}
              onClick={() => scrollToQuestion(index - 1)}
            />
          )}
        </div>
      ))}

      <div
        ref={shareRef}
        className="min-h-screen flex flex-col justify-center items-center p-8 relative bg-gray-200"
      >
        <h2 className="text-3xl font-bold mb-4">Quiz Completed!</h2>
        <p className="text-xl mb-8">
          Your final score: {score} / {currentQuizLength}
        </p>
        <button
          onClick={handleShare}
          className="px-4 py-2 bg-black text-white rounded-full hover:bg-gray-800 transition-colors flex items-center space-x-2"
        >
          <Share2 size={20} />
          <span>Share Your Score</span>
        </button>
      </div>

      <div className="fixed bottom-4 right-4 bg-black text-white px-4 py-2 rounded-full">
        Score: {score} / {currentQuizLength}
      </div>

      <Dialog open={showShareModal} onOpenChange={setShowShareModal}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Share Your Score</DialogTitle>
            <DialogDescription>
              Copy the text below to share your quiz results:
            </DialogDescription>
          </DialogHeader>
          <pre className="bg-gray-100 p-4 rounded-md whitespace-pre-wrap">
            {shareText}
          </pre>
          <div className="flex justify-end space-x-2">
            <Button onClick={() => setShowShareModal(false)}>
              <X className="mr-2 h-4 w-4" /> Close
            </Button>
            <Button onClick={handleCopy}>
              <Copy className="mr-2 h-4 w-4" /> {copyButtonText}
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default ScrollQuiz;
