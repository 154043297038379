import { Settings } from 'lucide-react';
import { useState } from 'react';
import { Button } from './ui/button';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from './ui/dialog';
import { Input } from './ui/input';
import { Label } from './ui/label';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from './ui/select';
import { Textarea } from './ui/textarea';

const SettingsModal = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [aiPlatform, setAiPlatform] = useState(
    localStorage.getItem('aiProvider') || 'openai',
  );
  const [openaiApiKey, setOpenaiApiKey] = useState(
    localStorage.getItem('openaiApiKey') || '',
  );
  const [groqApiKey, setGroqApiKey] = useState(
    localStorage.getItem('groqApiKey') || '',
  );
  const [numQuestions, setNumQuestions] = useState(
    localStorage.getItem('numQuestions') || 10,
  );
  const [extraPrompt, setExtraPrompt] = useState('');

  const handleSave = () => {
    // Here you would typically save the settings to your state management solution or backend
    localStorage.setItem('aiProvider', aiPlatform);
    localStorage.setItem('openaiApiKey', openaiApiKey);
    localStorage.setItem('groqApiKey', groqApiKey);
    localStorage.setItem('numQuestions', numQuestions.toString());
    setIsOpen(false);
  };

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogTrigger asChild>
        <Button variant="ghost" size="icon">
          <Settings className="h-4 w-4" />
        </Button>
      </DialogTrigger>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>Settings</DialogTitle>
        </DialogHeader>
        <div className="grid gap-4 py-4">
          <div className="grid grid-cols-4 items-center gap-4">
            <Label htmlFor="ai-platform" className="col-span-4">
              AI Platform
            </Label>
            <Select value={aiPlatform} onValueChange={setAiPlatform}>
              <SelectTrigger id="ai-platform">
                <SelectValue placeholder="Select AI platform" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="openai">OpenAI</SelectItem>
                <SelectItem value="groq">Groq</SelectItem>
              </SelectContent>
            </Select>
          </div>
          <div className="grid grid-cols-4 items-center gap-4">
            <Label htmlFor="openai-api-key" className="col-span-4">
              OpenAI API Key
            </Label>
            <Input
              id="openai-api-key"
              value={openaiApiKey}
              onChange={(e) => setOpenaiApiKey(e.target.value)}
              className="col-span-4"
              type="password"
            />
          </div>
          <div className="grid grid-cols-4 items-center gap-4">
            <Label htmlFor="groq-api-key" className="col-span-4">
              Groq API Key
            </Label>
            <Input
              id="groq-api-key"
              value={groqApiKey}
              onChange={(e) => setGroqApiKey(e.target.value)}
              className="col-span-4"
              type="password"
            />
          </div>
          <div className="grid grid-cols-4 items-center gap-4">
            <Label htmlFor="num-questions" className="col-span-4">
              Number of Questions to Generate
            </Label>
            <Input
              id="num-questions"
              type="number"
              value={numQuestions}
              onChange={(e) => setNumQuestions(parseInt(e.target.value))}
              className="col-span-4"
            />
          </div>
          <div className="grid grid-cols-4 items-center gap-4">
            <Label htmlFor="extra-prompt" className="col-span-4">
              Extra AI Prompt
            </Label>
            <Textarea
              id="extra-prompt"
              value={extraPrompt}
              onChange={(e) => setExtraPrompt(e.target.value)}
              className="col-span-4"
            />
          </div>
        </div>
        <Button onClick={handleSave}>Save changes</Button>
      </DialogContent>
    </Dialog>
  );
};

export default SettingsModal;
