import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Navbar } from './components/Navbar';
import ScrollQuiz from './components/ScrollQuiz';
import { Toaster } from './components/ui/toaster';
import MakeQuizPage from './pages/MakeQuizPage';
import QuizPage from './pages/QuizPage';
import quizStore from './store/QuizStore';
import './styles/index.css';

const App: React.FC = () => {
  const [activeComponent, setActiveComponent] = useState<string>('Quizzes');

  return (
    <>
      <Navbar
        activeComponent={activeComponent}
        setActiveComponent={setActiveComponent}
      />
      <Toaster />
      <div className="fullApp">
        <ToastContainer />
        {activeComponent === 'Quizzes' && (
          <QuizPage quizzes={quizStore.quizzes} />
        )}
        {activeComponent === 'GenerateQuestions' && <MakeQuizPage />}
        {activeComponent === 'ScrollQuiz' && <ScrollQuiz />}
      </div>
    </>
  );
};

export default observer(App);
