import { toast } from 'react-toastify';

export function quickToast(string: string) {
  const message = string.split('\n').map((item, i) => <p key={i}>{item}</p>);
  toast(<div>{message}</div>, { autoClose: 2000 });
}

export function showToast(string: string): () => void {
  const message = string.split('\n').map((item, i) => <p key={i}>{item}</p>);
  const id = toast(<div>{message}</div>, {
    autoClose: false,
  });
  const closeToast = () => {
    toast.dismiss(id);
  };
  return closeToast;
}

export function showDialog(string: string): () => void {
  const message = string.split('\n').map((item, i) => <p key={i}>{item}</p>);
  const id = toast(<div>{message}</div>, {
    autoClose: false,
    className: 'custom-toast',
    position: 'bottom-left',
  });
  const closeToast = () => {
    toast.dismiss(id);
  };
  return closeToast;
}
