import { Plus, X } from 'lucide-react';
import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import { Button } from '../components/ui/button';
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from '../components/ui/card';
import { Input } from '../components/ui/input';
import { ScrollArea } from '../components/ui/scroll-area';
import quizStore from '../store/QuizStore';

const CategoryManager: React.FC = observer(() => {
  const [newCategory, setNewCategory] = useState('');
  const categories = quizStore.categories;

  const handleAddCategory = () => {
    if (newCategory.trim()) {
      quizStore.addCategory(newCategory.trim());
      setNewCategory('');
    }
  };

  return (
    <Card className="w-full max-w-2xl mx-auto">
      <CardHeader>
        <CardTitle className="text-2xl font-bold">Manage Categories</CardTitle>
      </CardHeader>
      <CardContent>
        <ScrollArea className="h-[300px] pr-4 mb-4">
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-3">
            {categories.map((category) => (
              <Card
                key={category}
                className="p-2 flex justify-between items-center bg-secondary"
              >
                {category}
                <Button
                  variant="ghost"
                  size="icon"
                  onClick={() => quizStore.removeCategory(category)}
                  className="h-8 w-8 rounded-full"
                >
                  <X className="h-4 w-4" />
                </Button>
              </Card>
            ))}
          </div>
        </ScrollArea>
        <div className="flex items-center space-x-2 mt-4">
          <Input
            type="text"
            value={newCategory}
            onChange={(e) => setNewCategory(e.target.value)}
            placeholder="New Category"
            className="flex-grow"
          />
          <Button onClick={handleAddCategory} className="flex items-center">
            <Plus className="h-4 w-4 mr-2" /> Add Category
          </Button>
        </div>
      </CardContent>
    </Card>
  );
});

export default CategoryManager;
