import axios from 'axios';

const OPEN_AI_API_URL = 'https://api.openai.com/v1/chat/completions';
const ANTHROPIC_API_URL = 'https://api.anthropic.com/v1/messages';
const GROQ_API_URL = 'https://api.groq.com/openai/v1/chat/completions';

export interface Message {
  role: 'system' | 'user' | 'assistant';
  content: string;
}

type AIProvider = 'openai' | 'claude' | 'groq';

export async function callAi(
  messages: Message[],
  max_tokens = 2000,
): Promise<string> {
  const provider = localStorage.getItem('aiProvider') as AIProvider | 'openai';
  try {
    let response;
    switch (provider) {
      case 'openai':
        response = await axios.post(
          OPEN_AI_API_URL,
          {
            model: 'gpt-4o',
            messages: messages,
            max_tokens: max_tokens,
            temperature: 1,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('openaiApiKey')}`,
              'Content-Type': 'application/json',
            },
          },
        );
        return response.data.choices[0].message.content;

      case 'claude':
        response = await axios.post(
          ANTHROPIC_API_URL,
          {
            model: 'claude-3-sonnet-20240229',
            messages: messages,
            max_tokens: max_tokens,
            temperature: 1,
          },
          {
            headers: {
              'X-API-Key': localStorage.getItem('anthropicApiKey'),
              'Content-Type': 'application/json',
              'anthropic-version': '2023-06-01',
            },
          },
        );
        return response.data.content[0].text;

      case 'groq':
        response = await axios.post(
          GROQ_API_URL,
          {
            model: 'llama3-70b-8192',
            messages: messages,
            max_tokens: max_tokens,
            temperature: 1,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('groqApiKey')}`,
              'Content-Type': 'application/json',
            },
          },
        );
        return response.data.choices[0].message.content;

      default:
        throw new Error('Invalid AI provider specified');
    }
  } catch (error) {
    console.error(`Error calling ${provider.toUpperCase()} API:`, error);
    throw error;
  }
}
