import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { showToast } from 'src/utils/toast';
import quizStore from '../store/QuizStore';
import { Button } from './ui/button';
import { Card, CardContent, CardHeader, CardTitle } from './ui/card';
import { Form, FormControl, FormField, FormItem, FormLabel } from './ui/form';
import { Input } from './ui/input';
import { Separator } from './ui/separator';
import { useToast } from './ui/use-toast';

const GenerateQuestionsPane: React.FC = observer(() => {
  const { toast } = useToast();
  const [selectedQuizzes, setSelectedQuizzes] = useState<string[]>([]);
  const quizzes = quizStore.quizzes;
  const MAX_QUIZZES = 3;

  const form = useForm({
    defaultValues: {
      prompt: '',
    },
  });

  const onSubmitPrompt = async (data: { prompt: string }) => {
    toast({
      title: 'Generating quiz',
      description: 'Generating quiz from prompt...',
    });
    await quizStore.generateFromPrompt(data.prompt);
    toast({
      title: 'Quiz generated',
      description: 'Your quiz has been generated successfully.',
    });
  };

  const toggleQuizSelection = (quizName: string) => {
    setSelectedQuizzes((prevSelected) => {
      if (prevSelected.includes(quizName)) {
        return prevSelected.filter((name) => name !== quizName);
      } else if (prevSelected.length < MAX_QUIZZES) {
        return [...prevSelected, quizName];
      } else {
        toast({
          title: 'Selection limit reached',
          description: `You can only select up to ${MAX_QUIZZES} quizzes`,
          variant: 'destructive',
        });
        return prevSelected;
      }
    });
  };

  const generateFromPastQuizzes = () => {
    toast({
      title: 'Generating quiz',
      description: 'This may take a while...',
    });
    quizStore.generateFromPastQuizzes(selectedQuizzes).finally(() => {
      toast({
        title: 'Quiz generated',
        description: 'Your quiz has been generated successfully.',
      });
    });
  };

  return (
    <div className="space-y-8">
      <Card>
        <CardHeader>
          <CardTitle>Generate Questions</CardTitle>
        </CardHeader>
        <CardContent>
          <Form {...form}>
            <form
              onSubmit={form.handleSubmit(onSubmitPrompt)}
              className="space-y-4"
            >
              <FormField
                control={form.control}
                name="prompt"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Generate questions from a prompt</FormLabel>
                    <FormControl>
                      <Input placeholder="Enter your prompt here" {...field} />
                    </FormControl>
                  </FormItem>
                )}
              />
              <Button type="submit">Generate from prompt</Button>
            </form>
          </Form>
        </CardContent>
      </Card>

      <Separator />

      {/* <GenerateQuestionsPane /> */}
      <Card>
        <CardHeader>
          <CardTitle>From Categories</CardTitle>
        </CardHeader>
        <CardContent>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-2">
            {quizStore.categories.map((category) => (
              <Button
                key={category}
                onClick={() => {
                  const closeToast = showToast('Generating questions...');
                  quizStore
                    .generateQuestionsForCategory(category)
                    .finally(() => {
                      closeToast();
                    });
                }}
              >
                {category}
              </Button>
            ))}
          </div>
        </CardContent>
      </Card>

      <Separator />

      <Card>
        <CardHeader>
          <CardTitle>Select Existing Quizzes</CardTitle>
        </CardHeader>
        <CardContent>
          <div className="grid grid-cols-3 gap-4">
            {quizzes.map((quiz) => (
              <Card
                key={quiz.name}
                className={`cursor-pointer transition-all border-2 ${
                  selectedQuizzes.includes(quiz.name)
                    ? 'border-2 border-black'
                    : 'hover:border-2 hover:border-gray-300'
                }`}
                onClick={() => toggleQuizSelection(quiz.name)}
              >
                <CardHeader className={'pb-0'}>
                  <CardTitle className="text-lg">{quiz.name}</CardTitle>
                </CardHeader>
                <CardContent className={'pt-0'}>
                  <p>
                    {
                      quizStore.questions.filter(
                        (q) => q.quizName === quiz.name,
                      ).length
                    }{' '}
                    questions
                  </p>
                </CardContent>
              </Card>
            ))}
          </div>
          <Button
            onClick={generateFromPastQuizzes}
            disabled={selectedQuizzes.length === 0}
            className="mt-4"
          >
            Generate from selected quizzes
          </Button>
        </CardContent>
      </Card>
    </div>
  );
});

export default GenerateQuestionsPane;
