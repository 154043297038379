export const defaultQuizzes = [
  {
    name: 'Gusto Quiz (July 26 2024)',
    description: '',
    favourite: true,
    lastUpdated: new Date(),
  },
  {
    name: 'Gusto Quiz (July 19 2024)',
    description: '',
    favourite: true,
    lastUpdated: new Date(),
  },
];
export const defaultQuestions = [
  {
    id: 1,
    question: 'Lisbon, Eureka, and Meyer are all types of which fruit?',
    answer: 'Lemon',
    approved: true,
    number: 1,
    quizName: 'Gusto Quiz (July 26 2024)',
  },
  {
    id: 2,
    question:
      "In the TV show 'Friends', what is the name of Ross Geller's second wife?",
    answer: 'Emily',
    approved: true,
    number: 2,
    quizName: 'Gusto Quiz (July 26 2024)',
  },
  {
    id: 3,
    question: 'Who was the first president of the United States?',
    answer: 'George Washington',
    approved: true,
    number: 3,
    quizName: 'Gusto Quiz (July 26 2024)',
  },
  {
    id: 4,
    question: 'In which country is the oldest pub in the world?',
    answer: "Ireland (Sean's Bar in Athlone)",
    approved: true,
    number: 4,
    quizName: 'Gusto Quiz (July 26 2024)',
  },
  {
    id: 5,
    question:
      "Who is the author of the 1865 novel 'Alice's Adventures in Wonderland'?",
    answer: 'Lewis Carroll',
    approved: true,
    number: 5,
    quizName: 'Gusto Quiz (July 26 2024)',
  },
  {
    id: 6,
    question: 'Which country is the biggest exporter of palm oil?',
    answer: 'Indonesia',
    approved: true,
    number: 6,
    quizName: 'Gusto Quiz (July 26 2024)',
  },
  {
    id: 7,
    question: 'What is the traditional, colourful South Korean attire called?',
    answer: 'Hanbok',
    approved: true,
    number: 7,
    quizName: 'Gusto Quiz (July 26 2024)',
  },
  {
    id: 8,
    question: 'What is the capital of Kenya?',
    answer: 'Nairobi',
    approved: true,
    number: 8,
    quizName: 'Gusto Quiz (July 26 2024)',
  },
  {
    id: 9,
    question:
      "In what year did John F. Kennedy get assassinated, Martin Luther King Jr. deliver his 'I Have a Dream' speech, and the Beatles release their debut album 'Please Please Me'?",
    answer: '1963',
    approved: true,
    number: 9,
    quizName: 'Gusto Quiz (July 26 2024)',
  },
  {
    id: 10,
    question: "What soft drink was originally named 'Brad's Drink'?",
    answer: 'Pepsi',
    approved: true,
    number: 10,
    quizName: 'Gusto Quiz (July 26 2024)',
  },
  {
    id: 11,
    question: 'Which country is the most densely populated in the world?',
    answer: 'Monaco',
    approved: true,
    number: 11,
    quizName: 'Gusto Quiz (July 26 2024)',
  },
  {
    id: 12,
    question: 'In which sport is the Stanley Cup awarded?',
    answer: 'Ice Hockey',
    approved: true,
    number: 12,
    quizName: 'Gusto Quiz (July 26 2024)',
  },
  {
    id: 13,
    question: "Who said, 'To infinity and beyond'?",
    answer: 'Buzz Lightyear (in Toy Story)',
    approved: true,
    number: 13,
    quizName: 'Gusto Quiz (July 26 2024)',
  },
  {
    id: 14,
    question: 'In which city was Archduke Franz Ferdinand assassinated?',
    answer: 'Sarajevo',
    approved: true,
    number: 14,
    quizName: 'Gusto Quiz (July 26 2024)',
  },
  {
    id: 15,
    question: 'What 5 cards make up the worst possible poker hand?',
    answer: '2, 3, 4, 5, 7 (with at least one off suit)',
    approved: true,
    number: 15,
    quizName: 'Gusto Quiz (July 26 2024)',
  },
  {
    id: 16,
    question: "In internet slang, what does 'TL;DR' stand for?",
    answer: "Too Long; Didn't Read",
    approved: true,
    number: 16,
    quizName: 'Gusto Quiz (July 26 2024)',
  },
  {
    id: 17,
    question: 'Who is the most decorated Olympian of all time?',
    answer: 'Michael Phelps',
    approved: true,
    number: 17,
    quizName: 'Gusto Quiz (July 26 2024)',
  },
  {
    id: 18,
    question: "Which country's flag has more than 4 sides?",
    answer: 'Nepal',
    approved: true,
    number: 18,
    quizName: 'Gusto Quiz (July 26 2024)',
  },
  {
    id: 19,
    question:
      "Which song contains the lyrics 'Cause I got that sunshine in my pocket, got that good soul in my feet'?",
    answer: "Can't Stop the Feeling!' by Justin Timberlake",
    approved: true,
    number: 19,
    quizName: 'Gusto Quiz (July 26 2024)',
  },
  {
    id: 20,
    question: 'In which US state is Mount Rushmore located?',
    answer: 'South Dakota',
    approved: true,
    number: 20,
    quizName: 'Gusto Quiz (July 26 2024)',
  },
  {
    id: 21,
    question: 'What is the real name of the rapper known as Jay-Z?',
    answer: 'Shaw Carter',
    approved: true,
    number: 21,
    quizName: 'Gusto Quiz (July 26 2024)',
  },
  {
    id: 22,
    question:
      "Which movie studio's logo features an image of a boy fishing on the moon?",
    answer: 'DreamWorks',
    approved: true,
    number: 22,
    quizName: 'Gusto Quiz (July 26 2024)',
  },
  {
    id: 23,
    question: 'Which conglomerate owns Cadbury, Oreo, and belVita?',
    answer: 'Mondelez International',
    approved: true,
    number: 23,
    quizName: 'Gusto Quiz (July 26 2024)',
  },
  {
    id: 24,
    question:
      "In the Monty Hall problem, what is the optimal strategy? Keep your original choice, switch doors, or it doesn't matter.",
    answer:
      'Switch doors, because it increases your probability of winning from 1/3 to 2/3.',
    approved: true,
    number: 24,
    quizName: 'Gusto Quiz (July 26 2024)',
  },
  {
    id: 25,
    question:
      "In 'Take Me Out to the Ballgame,' what two snacks are mentioned?",
    answer: 'Peanuts and Cracker Jack',
    approved: true,
    number: 25,
    quizName: 'Gusto Quiz (July 26 2024)',
  },
  {
    id: 26,
    question:
      "In what year did the 'Bay of Pigs Invasion' take place, Yuri Gagarin became the first human to journey into outer space, and Barbie's boyfriend 'Ken' was first introduced?",
    answer: '1961',
    approved: true,
    number: 1,
    quizName: 'Gusto Quiz (July 19 2024)',
  },
  {
    id: 27,
    question: 'From which country did Gouda cheese originate?',
    answer: 'The Netherlands',
    approved: true,
    number: 2,
    quizName: 'Gusto Quiz (July 19 2024)',
  },
  {
    id: 28,
    question: 'Who is the founder of Virgin Group?',
    answer: 'Richard Branson',
    approved: true,
    number: 3,
    quizName: 'Gusto Quiz (July 19 2024)',
  },
  {
    id: 29,
    question:
      'In swimming, how many individual strokes are used in an Individual Medley (IM) race?',
    answer: 'Four (Butterfly, Backstroke, Breaststroke, Freestyle)',
    approved: true,
    number: 4,
    quizName: 'Gusto Quiz (July 19 2024)',
  },
  {
    id: 30,
    question: "Whose slogan is 'Don’t Leave Home Without It'?",
    answer: 'American Express',
    approved: true,
    number: 5,
    quizName: 'Gusto Quiz (July 19 2024)',
  },
  {
    id: 31,
    question: 'What device is used to measure atmospheric pressure?',
    answer: 'Barometer',
    approved: true,
    number: 6,
    quizName: 'Gusto Quiz (July 19 2024)',
  },
  {
    id: 32,
    question: 'What is the capital of Argentina?',
    answer: 'Buenos Aires',
    approved: true,
    number: 7,
    quizName: 'Gusto Quiz (July 19 2024)',
  },
  {
    id: 33,
    question:
      'Which actor plays the role of Mr. Bean in the movies and TV series?',
    answer: 'Rowan Atkinson',
    approved: true,
    number: 8,
    quizName: 'Gusto Quiz (July 19 2024)',
  },
  {
    id: 34,
    question: 'Which conglomerate owns Maybelline, Garnier and CeraVe?',
    answer: "L'Oréal",
    approved: true,
    number: 9,
    quizName: 'Gusto Quiz (July 19 2024)',
  },
  {
    id: 35,
    question: 'Which country is the top producer of wool?',
    answer: 'Australia',
    approved: true,
    number: 10,
    quizName: 'Gusto Quiz (July 19 2024)',
  },
  {
    id: 36,
    question:
      'Which novel and later film, revolves around the love life of Allie Hamilton and Noah Calhoun?',
    answer: 'The Notebook',
    approved: true,
    number: 11,
    quizName: 'Gusto Quiz (July 19 2024)',
  },
  {
    id: 37,
    question: 'Who was the lead scientist of the Manhattan Project?',
    answer: 'Robert Oppenheimer',
    approved: true,
    number: 12,
    quizName: 'Gusto Quiz (July 19 2024)',
  },
  {
    id: 38,
    question:
      'Which song includes the lyrics “I wanna stand with you on a mountain, I wanna bathe with you in the sea”?',
    answer: "Truly Madly Deeply' by Savage Garden",
    approved: true,
    number: 13,
    quizName: 'Gusto Quiz (July 19 2024)',
  },
  {
    id: 39,
    question: 'In technology, what does VPN stand for?',
    answer: 'Virtual private network',
    approved: true,
    number: 14,
    quizName: 'Gusto Quiz (July 19 2024)',
  },
  {
    id: 40,
    question: 'How many chambers are there in the human heart?',
    answer: 'Four',
    approved: true,
    number: 15,
    quizName: 'Gusto Quiz (July 19 2024)',
  },
  {
    id: 41,
    question:
      "Coldplay collaborated with which major female artist on the song 'Princess of China'?",
    answer: 'Rihanna',
    approved: true,
    number: 16,
    quizName: 'Gusto Quiz (July 19 2024)',
  },
  {
    id: 42,
    question:
      'In Greek mythology, who was the king who turned all he touched into gold?',
    answer: 'King Midas',
    approved: true,
    number: 17,
    quizName: 'Gusto Quiz (July 19 2024)',
  },
  {
    id: 43,
    question:
      "What film portrays a young girl's adventure in a hidden world with strange creatures after her parents are turned into pigs?",
    answer: 'Spirited Away',
    approved: true,
    number: 18,
    quizName: 'Gusto Quiz (July 19 2024)',
  },
  {
    id: 44,
    question:
      "Which Korean automobile company is responsible for the 'Elantra' and 'Santa Fe'?",
    answer: 'Hyundai',
    approved: true,
    number: 19,
    quizName: 'Gusto Quiz (July 19 2024)',
  },
  {
    id: 45,
    question: 'Where in the body would you find the latissimus dorsi?',
    answer: 'Back',
    approved: true,
    number: 20,
    quizName: 'Gusto Quiz (July 19 2024)',
  },
  {
    id: 46,
    question: "What is a 'Full House' in poker?",
    answer: "It's a hand that contains three of a kind and a pair",
    approved: true,
    number: 21,
    quizName: 'Gusto Quiz (July 19 2024)',
  },
  {
    id: 47,
    question:
      'What type of cocktail typically includes vodka, tomato juice, and various spices?',
    answer: 'Bloody Mary',
    approved: true,
    number: 22,
    quizName: 'Gusto Quiz (July 19 2024)',
  },
  {
    id: 48,
    question: 'Which astrological star signs fall in the month of July?',
    answer: 'Cancer and Leo',
    approved: true,
    number: 23,
    quizName: 'Gusto Quiz (July 19 2024)',
  },
  {
    id: 49,
    question: 'Which state in the USA shares the longest border with Mexico?',
    answer: 'Texas',
    approved: true,
    number: 24,
    quizName: 'Gusto Quiz (July 19 2024)',
  },
  {
    id: 50,
    question:
      'How many tiles are you allowed to have on your rack in the standard version of Scrabble?',
    answer: '7',
    approved: true,
    number: 25,
    quizName: 'Gusto Quiz (July 19 2024)',
  },
  {
    id: 51,
    question:
      'What is the total number of double-letter score tiles on a standard Scrabble board?',
    answer: '12',
    approved: false,
  },
  {
    id: 52,
    question: "In Scrabble, how many points is the letter 'Q' worth?",
    answer: '10 points',
    approved: false,
  },
  {
    id: 53,
    question: 'Which two letters have the highest point value in Scrabble?',
    answer: 'Q and Z',
    approved: false,
  },
  {
    id: 54,
    question:
      'How many triple-word score tiles are there on a standard Scrabble board?',
    answer: '8',
    approved: false,
  },
  {
    id: 55,
    question:
      'What is the maximum number of tiles a player can exchange on their turn in Scrabble?',
    answer: '7',
    approved: false,
  },
  {
    id: 56,
    question: 'How many blank tiles are there in a standard Scrabble set?',
    answer: '2',
    approved: false,
  },
  {
    id: 57,
    question: "How many points is the letter 'E' worth in Scrabble?",
    answer: '1 point',
    approved: false,
  },
  {
    id: 58,
    question:
      'What is the highest possible score for a single word in Scrabble?',
    answer: 'Oxyphenbutazone, up to 1,782 points',
    approved: false,
  },
  {
    id: 59,
    question: 'In Scrabble, how many tiles are in total in the game?',
    answer: '100',
    approved: false,
  },
  {
    id: 60,
    question:
      'What is the minimum number of players required to play a game of Scrabble?',
    answer: '2',
    approved: false,
  },
  {
    id: 61,
    question:
      "Which German automobile company produces the 'A4' and 'Q7' models?",
    answer: 'Audi',
    approved: false,
  },
  {
    id: 62,
    question:
      "Which Japanese automobile company is known for the 'Corolla' and 'Camry'?",
    answer: 'Toyota',
    approved: false,
  },
  {
    id: 63,
    question:
      "Which South Korean company manufactures the 'Kona' and 'Sorento'?",
    answer: 'Kia',
    approved: false,
  },
  {
    id: 64,
    question:
      "Which American automobile company produces the 'Mustang' and 'F-150'?",
    answer: 'Ford',
    approved: false,
  },
  {
    id: 65,
    question:
      "Which Italian luxury sports car brand is known for models like the '488' and 'Portofino'?",
    answer: 'Ferrari',
    approved: false,
  },
  {
    id: 66,
    question:
      "Which French automobile company is responsible for the 'Clio' and 'Megane'?",
    answer: 'Renault',
    approved: false,
  },
  {
    id: 67,
    question:
      "Which Swedish company is known for producing the 'XC90' and 'S60' models?",
    answer: 'Volvo',
    approved: false,
  },
  {
    id: 68,
    question:
      "Which Korean automobile company produces the 'Optima' and 'Sportage'?",
    answer: 'Kia',
    approved: false,
  },
  {
    id: 69,
    question:
      "Which British luxury automobile company manufactures the 'Phantom' and 'Ghost'?",
    answer: 'Rolls-Royce',
    approved: false,
  },
  {
    id: 70,
    question:
      "Which American electric vehicle company is known for the 'Model S' and 'Model X'?",
    answer: 'Tesla',
    approved: false,
  },
  {
    id: 71,
    question: 'What is the largest desert in Africa?',
    answer: 'The Sahara Desert',
    approved: false,
  },
  {
    id: 72,
    question: 'Which African country has the highest population?',
    answer: 'Nigeria',
    approved: false,
  },
  {
    id: 73,
    question: 'What is the capital of Egypt?',
    answer: 'Cairo',
    approved: false,
  },
  {
    id: 74,
    question:
      'What river runs through six countries in northeastern Africa, including Uganda and Sudan?',
    answer: 'The Nile River',
    approved: false,
  },
  {
    id: 75,
    question: 'Which African country is known as the Land of a Thousand Hills?',
    answer: 'Rwanda',
    approved: false,
  },
  {
    id: 76,
    question: 'What is the capital of South Africa?',
    answer:
      'Pretoria (administrative), Bloemfontein (judicial), and Cape Town (legislative)',
    approved: false,
  },
  {
    id: 77,
    question: 'What is the capital of Nigeria?',
    answer: 'Abuja',
    approved: false,
  },
  {
    id: 78,
    question: 'Which country is Mount Kilimanjaro located in?',
    answer: 'Tanzania',
    approved: false,
  },
  {
    id: 79,
    question: 'What is the capital of Ethiopia?',
    answer: 'Addis Ababa',
    approved: false,
  },
  {
    id: 80,
    question: 'What is the smallest country in Africa by land area?',
    answer: 'Seychelles',
    approved: false,
  },
  {
    id: 81,
    question: 'In which city was John F. Kennedy assassinated?',
    answer: 'Dallas',
    approved: false,
  },
  {
    id: 82,
    question:
      "What was the title of Martin Luther King Jr.'s famous speech delivered during the March on Washington?",
    answer: "'I Have a Dream'",
    approved: false,
  },
  {
    id: 83,
    question: "What was the name of the Beatles' debut studio album?",
    answer: "'Please Please Me'",
    approved: false,
  },
  {
    id: 84,
    question: 'Who assassinated John F. Kennedy?',
    answer: 'Lee Harvey Oswald',
    approved: false,
  },
  {
    id: 85,
    question:
      'What year did Martin Luther King Jr. receive the Nobel Peace Prize?',
    answer: '1964',
    approved: false,
  },
  {
    id: 86,
    question: "Which Beatle was known as the 'quiet Beatle'?",
    answer: 'George Harrison',
    approved: false,
  },
  {
    id: 87,
    question: 'In what year was Martin Luther King Jr. assassinated?',
    answer: '1968',
    approved: false,
  },
  {
    id: 88,
    question:
      "Which U.S. President was in office when Martin Luther King Jr. delivered his 'I Have a Dream' speech?",
    answer: 'John F. Kennedy',
    approved: false,
  },
  {
    id: 89,
    question:
      "What was the release date of The Beatles' album 'Please Please Me'?",
    answer: 'March 22, 1963',
    approved: false,
  },
  {
    id: 90,
    question: 'What did John F. Kennedy famously say in his inaugural address?',
    answer:
      "'Ask not what your country can do for you – ask what you can do for your country.'",
    approved: false,
  },
];
