import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import quizStore, { QuestionObj } from '../store/QuizStore';

interface QuestionReorderProps {
  questions: QuestionObj[];
}

const QuestionReorder: React.FC<QuestionReorderProps> = observer(
  ({ questions }) => {
    const [localQuestions, setLocalQuestions] = useState(questions);

    const onDragStart = (index: number) => {
      setDraggedIndex(index);
    };

    const onDragOver = (index: number) => {
      setDraggedOverIndex(index);
    };

    const onDrop = () => {
      if (draggedIndex !== null && draggedOverIndex !== null) {
        const reorderedQuestions = [...localQuestions];
        const [removed] = reorderedQuestions.splice(draggedIndex, 1);
        reorderedQuestions.splice(draggedOverIndex, 0, removed);
        updateQuestionNumbers(reorderedQuestions);
        setLocalQuestions(reorderedQuestions);
        setDraggedIndex(null);
        setDraggedOverIndex(null);
      }
    };

    const updateQuestionNumbers = (questions: QuestionObj[]) => {
      questions.forEach((question, index) => {
        quizStore.changeNumber(question.id, index + 1);
        question.number = index + 1;
      });
    };

    const shuffleQuestions = () => {
      const shuffled = [...localQuestions];
      for (let i = shuffled.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
      }
      updateQuestionNumbers(shuffled);
      setLocalQuestions(shuffled);
    };

    const [draggedIndex, setDraggedIndex] = useState<number | null>(null);
    const [draggedOverIndex, setDraggedOverIndex] = useState<number | null>(
      null,
    );

    const sortedQuestions = [...localQuestions].sort(
      (a, b) => (a.number || 0) - (b.number || 0),
    );

    return (
      <div>
        <button
          onClick={shuffleQuestions}
          style={{
            padding: '8px 16px',
            margin: '8px 0',
            backgroundColor: '#4CAF50',
            color: 'white',
            border: 'none',
            borderRadius: '4px',
            cursor: 'pointer',
          }}
        >
          Shuffle Questions
        </button>
        {sortedQuestions.map((question, index) => (
          <div
            key={question.id}
            draggable
            onDragStart={() => onDragStart(index)}
            onDragOver={(e) => {
              e.preventDefault();
              onDragOver(index);
            }}
            onDrop={onDrop}
            style={{
              padding: '8px',
              margin: '4px',
              backgroundColor:
                draggedOverIndex === index ? 'lightblue' : 'white',
              border: '1px solid #ccc',
              cursor: 'move',
            }}
          >
            {question.number}. {question.question}
          </div>
        ))}
      </div>
    );
  },
);

export default QuestionReorder;
