import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import quizStore, { QuizObj } from '../store/QuizStore';

import PaginationComponent from 'src/components/PaginationComponent';
import QuestionTable from '../components/QuestionTable';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from '../components/ui/alert-dialog';
import { Button } from '../components/ui/button';
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
} from '../components/ui/card';
import { Input } from '../components/ui/input';
import { Label } from '../components/ui/label';
import { Separator } from '../components/ui/separator';
import { Textarea } from '../components/ui/textarea';
import { useToast } from '../components/ui/use-toast';

const QuizPage: React.FC<{ quizzes: QuizObj[] }> = ({ quizzes }) => {
  const [createQuizName, setQuizName] = useState<string>('');
  const [editQuizName, setEditQuizName] = useState<string>('');
  const [quizDescription, setQuizDescription] = useState<string>('');
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [localQuizzes, setLocalQuizzes] = useState<QuizObj[]>(quizzes);
  const [draggedQuiz, setDraggedQuiz] = useState<QuizObj | null>(null);

  const itemsPerPage = 25;

  const { toast } = useToast();

  useEffect(() => {
    setLocalQuizzes(quizzes);
  }, [quizzes]);

  useEffect(() => {
    if (quizStore.currentQuiz) {
      const currentQuizObj = localQuizzes.find(
        (q) => q.name === quizStore.currentQuiz,
      );
      setQuizDescription(currentQuizObj?.description || '');
    }
  }, [quizStore.currentQuiz, localQuizzes]);

  const paginatedQuizzes = localQuizzes.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage,
  );

  const handleEditQuizName = () => {
    if (editQuizName !== '') {
      quizStore.renameQuiz(quizStore.currentQuiz, editQuizName);
      setIsEditing(false);
    }
  };

  const handleEditDescription = () => {
    quizStore.updateQuizDescription(quizStore.currentQuiz, quizDescription);
  };

  const handleDeleteQuiz = () => {
    if (!quizStore.currentQuiz) {
      throw new Error('No quiz selected');
    }
    const currentQuiz = quizStore.currentQuiz;
    // Delete quiz changes the current quiz to the next one
    quizStore.deleteQuiz(quizStore.currentQuiz);
    setLocalQuizzes(
      localQuizzes.filter((q) => q.name !== quizStore.currentQuiz),
    );
    toast({
      title: 'Quiz Deleted',
      description: `The quiz "${currentQuiz}" has been deleted.`,
    });
  };

  const handleDragStart = (
    e: React.DragEvent<HTMLDivElement>,
    quiz: QuizObj,
  ) => {
    setDraggedQuiz(quiz);
    e.dataTransfer.setData('text/plain', quiz.name);
    e.currentTarget.style.opacity = '0.5';
  };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.currentTarget.style.background = '#f0f0f0';
  };

  const handleDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    e.currentTarget.style.background = '';
  };

  const handleDrop = (
    e: React.DragEvent<HTMLDivElement>,
    targetQuiz: QuizObj,
  ) => {
    e.preventDefault();
    e.currentTarget.style.background = '';

    if (draggedQuiz) {
      const targetIndex = localQuizzes.findIndex(
        (q) => q.name === targetQuiz.name,
      );
      const updatedQuizzes = localQuizzes.filter(
        (q) => q.name !== draggedQuiz.name,
      );
      // if current quiz index is after the dragged quiz index then add 1
      updatedQuizzes.splice(targetIndex, 0, draggedQuiz);

      setLocalQuizzes(updatedQuizzes);
      quizStore.reorderQuiz(draggedQuiz.name, targetIndex);
    }

    setDraggedQuiz(null);
  };

  const handleDragEnd = (e: React.DragEvent<HTMLDivElement>) => {
    e.currentTarget.style.opacity = '1';
  };

  const toggleFavourite = (quizName: string, event: React.MouseEvent) => {
    event.stopPropagation();
    quizStore.toggleFavourite(quizName);
  };

  return (
    <div className="flex flex-row space-x-4 p-4">
      <div className="w-1/2 min-w-fit">
        <Card className="p-6">
          <CardHeader>
            <h3 className="text-2xl font-semibold">Create Quiz</h3>
          </CardHeader>
          <CardContent className="space-y-4">
            <div className="space-y-2">
              <Label htmlFor="quiz-name">Quiz Name</Label>
              <Input
                id="quiz-name"
                type="text"
                value={createQuizName}
                onChange={(e) => setQuizName(e.target.value)}
                placeholder="Enter quiz name"
              />
            </div>
            <Button
              onClick={() => {
                if (createQuizName === '') return;
                const newQuiz = quizStore.createNewQuiz(createQuizName);
                if (!newQuiz) return;
                quizStore.setCurrentQuiz(createQuizName);
                setLocalQuizzes([newQuiz, ...localQuizzes]);
                setQuizName('');
              }}
              className="w-full"
              disabled={createQuizName === ''}
            >
              Create New Quiz
            </Button>
            <Separator />
            <div className="space-y-2">
              <Label htmlFor="file-upload">Import Quiz</Label>
              <Input
                id="file-upload"
                type="file"
                accept=".tsv"
                onChange={(e) => quizStore.importFromTSV(e.target.files?.[0])}
              />
            </div>
          </CardContent>
          <CardFooter className="text-sm text-gray-500">
            You can create a new quiz or import from a TSV file.
          </CardFooter>
          <Separator className="mb-8" />
          <h3 className="text-2xl font-semibold mb-4">Select Quiz</h3>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
            {paginatedQuizzes.map((quiz) => (
              <Card
                key={quiz.name}
                className={`cursor-pointer transition-all duration-200 hover:shadow-md ${
                  quiz.name === quizStore.currentQuiz
                    ? 'ring-2 ring-primary'
                    : ''
                }`}
                onClick={() => quizStore.setCurrentQuiz(quiz.name)}
                draggable
                onDragStart={(e) => handleDragStart(e, quiz)}
                onDragOver={handleDragOver}
                onDragLeave={handleDragLeave}
                onDrop={(e) => handleDrop(e, quiz)}
                onDragEnd={handleDragEnd}
              >
                <CardHeader className="pb-0">
                  <div className="flex justify-between items-center">
                    <h3 className="text-lg font-semibold truncate">
                      {quiz.name}
                    </h3>
                    <Button
                      variant="ghost"
                      size="sm"
                      className="ml-2 flex-shrink-0"
                      onClick={(e) => {
                        e.stopPropagation();
                        toggleFavourite(quiz.name, e);
                      }}
                    >
                      {quiz.favourite ? '★' : '☆'}
                    </Button>
                  </div>
                </CardHeader>
                <CardContent className="pb-6">
                  <p className="text-sm text-muted-foreground">
                    {
                      quizStore.questions.filter(
                        (q) => q.quizName === quiz.name,
                      ).length
                    }{' '}
                    questions
                  </p>
                </CardContent>
              </Card>
            ))}
          </div>
          {paginatedQuizzes.length !== localQuizzes.length &&
            localQuizzes.length > itemsPerPage && (
              <div className="flex items-center justify-between space-x-2 py-4">
                <PaginationComponent
                  currentPage={currentPage}
                  totalPages={localQuizzes.length / itemsPerPage}
                  setCurrentPage={setCurrentPage}
                />
              </div>
            )}
        </Card>
      </div>
      <div className="w-1/2 min-w-fit">
        <Card className="p-6">
          {isEditing ? (
            <div className="mb-4">
              <Input
                type="text"
                value={editQuizName}
                onChange={(e) => setEditQuizName(e.target.value)}
                className="mb-2"
              />
              <Button onClick={handleEditQuizName} className="mr-2">
                Save
              </Button>
              <Button variant="outline" onClick={() => setIsEditing(false)}>
                Cancel
              </Button>
            </div>
          ) : (
            <div className="flex items-center mb-4">
              <h1 className="text-2xl font-bold mr-2">
                {quizStore.currentQuiz}
              </h1>
              <Button variant="outline" onClick={() => setIsEditing(true)}>
                Edit
              </Button>
            </div>
          )}
          <div className="mb-4">
            <Textarea
              value={quizDescription}
              onChange={(e) => setQuizDescription(e.target.value)}
              placeholder="Add a description for this quiz"
              className="mb-2"
            />
            <Button onClick={handleEditDescription}>Save Description</Button>
          </div>
          <div className="flex mb-4">
            <Button
              onClick={() => quizStore.exportToTSV(quizStore.currentQuiz)}
              className="mr-2"
            >
              Export to TSV
            </Button>
            <Button
              onClick={() => quizStore.exportToCanvaCSV(quizStore.currentQuiz)}
              className="mr-2"
            >
              Export for Canva
            </Button>
            <AlertDialog
              open={isDeleteDialogOpen}
              onOpenChange={setIsDeleteDialogOpen}
            >
              <AlertDialogTrigger asChild>
                <Button variant="destructive">Delete</Button>
              </AlertDialogTrigger>
              <AlertDialogContent>
                <AlertDialogHeader>
                  <AlertDialogTitle>
                    Are you sure you want to delete this quiz?
                  </AlertDialogTitle>
                  <AlertDialogDescription>
                    This action cannot be undone. This will permanently delete
                    the "{quizStore.currentQuiz}" quiz and all its questions.
                  </AlertDialogDescription>
                </AlertDialogHeader>
                <AlertDialogFooter>
                  <AlertDialogCancel>Cancel</AlertDialogCancel>
                  <AlertDialogAction onClick={handleDeleteQuiz}>
                    Delete
                  </AlertDialogAction>
                </AlertDialogFooter>
              </AlertDialogContent>
            </AlertDialog>
          </div>
          <QuestionTable
            questions={quizStore.currentQuizQuestions}
            isSingleQuiz={true}
          />
        </Card>
      </div>
    </div>
  );
};

export default observer(QuizPage);
