export const GENERATE_SIMILAR_QUESTIONS = `Complete the following tasks
1) Given this trivia question give me all the subjects and categories
2) Generate 10 new trivia questions from the subjects and categories. Output this as a json with the keys "question" and "answer"`;

export const EXPLAIN_FORMAT = `You are an expert quiz writer. You will be asked to write a quiz. ALL RESPONSES MUST BE RETURNED AS A JSON LIST WITH THE VALUES "question" and "answer" and MUST NOT have question numbers!`;

export const FACT_CHECK = `Let me know if this is the only correct answer for this question. If there are any ambiguities point them out.`;

export const GENERATE_QUESTIONS_FROM_PAST_QUIZZES = `You are an expert quiz writer who's been tasked with writing this weeks quiz! It MUST be extremely similar to the existing quizzes. This new quiz should be written in exactly the same style with the same question choice and it should not be clear that someone else wrote it.

Generate a quiz. Output it as a json list with the values "question" and "answer"

Here are all previous quizzes:
`;

export const CATEGORIZE_QUESTIONS = `Given a set of categories and a set of quiz questions please categorise all questions into a category (keep their question number) and suggest new categories that aren't covered.

You must output it as a JSON with the fields "number", "question", "answer", "category"`;
